.page-container {
  padding: calc(var(--bs-gutter-x) * 0.5);
}

.pointer {
  cursor: pointer;
}

@import './layout.scss';
@import './form.scss';
@import './header.scss';
@import './sidermenu.scss';
@import './table.scss';
@import './checkboxMenu.scss';
@import './button.scss';
