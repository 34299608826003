th {
  vertical-align: middle;
  > span {
    display: inline-block;
    > .th-title {
      display: inline-block;
      /* flex: 1; */
    }
    > .th-actions {
      display: inline-block;
      > .th-action {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}

.sst-horizontal-table {
  display: flex;
}

.btn.btn-modal {
  width: 4rem;
}

.btn.btn-modal.btn-modal-large {
  width: 6rem;
}
