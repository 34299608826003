html,
body,
#root {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
}

.card {
  margin-bottom: 16px;
}

@import './common/index.scss';
@import './login/index.scss';
