.btn {
  &.btn-outline-primary,
  &.btn-outline-secondary,
  &.btn-outline-success,
  &.btn-outline-warning {
    &.btn-icon {
      border: none;

      button > {
        padding: 0;
      }

      &:hover {
        background-color: transparent;
        border: none;
      }
    }
  }
}
