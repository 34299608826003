$primary: #0097a7;
$secondary: #424242;
$success: #a7c500;
$danger: #d32f2f;
$warning: #ff8e05;
$background: #f0f2f5;
$card: white;

$btn-color: #eceded;
$body-bg: #eceded;

$header-height: 50px;
$header-bg: white;

$font-family: Lato, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
