.sider-menu {
  max-width: 240px;
  min-width: 240px;
  min-height: 100vh;
  width: 240px;
  background-color: #{$secondary}; // var(--bs-secondary-color);
  color: #{$body-bg};
  font-weight: 400;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  display: flex;
  flex-direction: column;

  .sider-menu-logo-container {
    text-align: center;
    padding: 2px;
    height: $header-height;
    max-height: $header-height;
    margin-bottom: 16px;

    display: flex;

    .sider-menu-logo {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      > img {
        width: 100%;
        max-height: 100%;
        margin: auto;
      }

      .sider-menu-logo-full {
        display: block;
      }
      .sider-menu-logo-short {
        display: none;
      }
    }
    > .sider-menu-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      margin: 2px;
      cursor: pointer;
    }
  }

  .exandable-icon-menu {
    font-size: 26px;
    cursor: pointer;
    text-align: center;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .main-menu {
    flex: 1;
    display: flex;
    flex-direction: column;

    hr {
      display: none;
    }

    .menu-content {
      flex: 1;
    }
    .menu-footer {
      padding: 8px 16px;

      button {
        width: 100%;
      }
    }
  }

  .menu-elem-group-title {
    transform: scale(1.1, 0.9) translateX(0.5rem);
    text-transform: uppercase;
    font-size: 12px;
    pointer-events: none;
    padding: 8px 16px;
    line-height: 1.5715rem;
    opacity: 0.65;
  }

  .menu-elem {
    margin-bottom: 8px;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
    margin-top: 4px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;

    a {
      max-width: 240px;
      min-width: 240px;
      width: 240px;
      height: 100%;
      padding: 0 0 0 28px;
    }

    .menu-elem-icon {
      font-size: 1.2rem;
      color: #{$body-bg};
      /* width: 2.5rem;
      height: 2.5rem; */
      display: inline-block;
    }

    .menu-elem-label {
      font-size: 14px;
      padding-left: 1.7rem;
      color: #{$body-bg};
      text-decoration: none;
      display: inline-block;
    }
  }
  .menu-elem.selected {
    background-color: darken($color: #424242, $amount: 2);
    border-left: 3px solid #{$card};
  }
  .menu-elem:hover {
    background-color: darken($color: #424242, $amount: 2);
  }

  &.collapsed {
    width: 80px;
    max-width: 80px;
    min-width: 80px;

    .sider-menu-logo-container {
      .sider-menu-logo {
        .sider-menu-logo-full {
          display: none;
        }
        .sider-menu-logo-short {
          display: block;
        }
      }

      .sider-menu-btn {
        display: none;
      }
    }

    .menu-elem-group-title {
      display: none;
    }
    hr {
      display: block;
    }
    .menu-content li:first-child > hr {
      display: none;
    }
    .menu-elem-label {
      display: none;
    }
  }
}
